.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.custom-btn-css{
  font-size: 16px;
  font-weight: bold;
  border-radius: 90px;
  border-width: 0;
  margin-top: 15px;
  padding: 10px 32px;
  color: white;
}

.btn-info{
  font-size: 21px;
  display: inline-block;
  background-color: #FFB6C1;
  padding: 20px;
  width: 200px;
  color: black;
  text-align: center;
  border: 1px solid #FFC8D2;
  border-radius: 10px;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.background {
  display: block;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 1;
}

.container { 
  border: 0.5px solid #000000; 
  /*box-shadow: 0 0 1px #000000; // FF96A0*/
  margin: 30px auto;
  padding: 30px;
  border-radius: 10px;
  min-width: 300px;
  max-width: 400px;
  min-height: 100px;
  text-align: left;
  overflow: auto;
  background-color: white;
  top: 33%;
  left: 50%;
  width: 25%;
  position: absolute; 
  transform: translate(-50%, -50%);
  z-index: 2;
}

.background-2 {
  display: block;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 1;
}

.container-2 { 
  /*border: 0.5px solid #000000;*/
  /*box-shadow: 0 0 1px #000000; // FF96A0*/
  margin: 40px auto;
  padding: 30px;
  border-radius: 10px;
  min-width: 600px;
  max-width: 800px;
  min-height: 500px;
  text-align: left;
  overflow: auto;
  background-color: white;
  top: 33%;
  left: 50%;
  width: 25%;
  position: absolute; 
  transform: translate(-50%, -50%);
  z-index: 2;
}

@media (max-width: 600px) {
  .background-2 {
    display: block;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 1;
  }
  
  .container-2 { 
    /*border: 0.5px solid #000000;*/
    /*box-shadow: 0 0 1px #000000; // FF96A0*/
    margin: 100px auto;
    padding: 30px;
    border-radius: 10px;
    min-width: 300px;
    max-width: 800px;
    min-height: 500px;
    text-align: left;
    overflow: auto;
    background-color: white;
    top: 33%;
    left: 50%;
    width: 25%;
    position: absolute; 
    transform: translate(-50%, -50%);
    z-index: 2;
  }
}

.btn-info:hover {
  background-color: #FF96A0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
